<template>
  <Layout :hasFilter="false" @filter="filter" :hasPagination="false">
    <template #menu>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item style="cursor: pointer">
            <v-list-item-title v-ripple @click="onButtonPress()"
              >Manage Tags</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <v-dialog v-model="popUpForm" max-width="800px">
      <manage-tags
        :customerId="customerId"
        @showCreateDialog="showCreateDialog = true"
      />
    </v-dialog>
    <div class="mt-3">
      <h3>Tags</h3>
      <div class="row tag-border mt-1" v-if="tags.length > 0">
        <v-chip-group active-class="primary--text" column>
          <v-chip v-for="tag in tags" :key="tag.id">
            {{ tag.name }}
          </v-chip>
        </v-chip-group>
      </div>
      <div class="row mt-1" v-else>
        No Tags Assigned
      </div>
    </div>
  </Layout>
</template>
<script>
import util from "@/util/util";
import Layout from "@/components/Contact/PersonalContactsInfos/Layout.vue";
import ManageTags from "../PopupForms/AddTags.vue";
export default {
  components: { Layout, ManageTags },
  data() {
    return {
      popUpForm: false,
      tags: [],
      customerId: this.$route.params.id,
      showCreateDialog: false,
      filterCriteria: { q: "" },
    };
  },
  mounted() {
    this.fetchTags();
    util.event.$on("refreshResource", () => {
      this.fetchTags();
      this.popUpForm = false;
    });
  },
  methods: {
    fetchTags() {
      util
        .http({
          url: "customer/" + this.$route.params.id + "/tag/",
          params: this.filterCriteria,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 0) {
            this.tags = [];
          } else {
            this.tags = res.data.data;
          }
        });
    },
    filter(n) {
      this.filterCriteria = n;
    },
    onButtonPress() {
      this.popUpForm = true;
    },
  },
  watch: {
    "$route.params.id": function() {
      this.customerId = this.$route.params.id;
    },
    filterCriteria: {
      handler() {
        this.fetchTags();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.col-data {
  border: 1px solid #000;
}
.bg-btn {
  background: #1d2b58 !important;
  color: white !important;
}
.tag-border {
  border: 1px solid #1d2b58;
  margin-left: 3px !important;
}
</style>
